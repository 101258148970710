async function deleteAllIndexedDBDatabases(): Promise<void> {
  try {
    if (!window.indexedDB || typeof window.indexedDB.databases !== "function") {
      throw new Error("IndexedDB or databases() method not supported");
    }

    const databases = await window.indexedDB.databases().catch(error => {
      throw new Error(`Failed to fetch databases: ${error.message}`);
    });

    if (!Array.isArray(databases)) {
      throw new Error("Unexpected result from indexedDB.databases()");
    }

    for (const db of databases) {
      if (db && db.name && typeof db.name === "string") {
        await new Promise<void>((resolve, reject) => {
          try {
            const request = window.indexedDB.deleteDatabase(db.name as string);
            request.onsuccess = () => {
              resolve();
            };
            request.onerror = (event: Event) => {
              const target = event.target as IDBOpenDBRequest;
              reject(
                target && target.error ? target.error : new Error("Unknown error occurred during database deletion"),
              );
            };
            request.onblocked = () => {
              reject(new Error(`Database ${db.name} is blocked and cannot be deleted`));
            };
          } catch (error) {
            reject(new Error(`Failed to initiate database deletion: ${(error as Error).message}`));
          }
        });
      }
    }
  } catch (error) {
    console.error("Error in deleteAllIndexedDBDatabases:", error);
  }
}

export { deleteAllIndexedDBDatabases };
