import { useEffect } from "react";

import dynamic from "next/dynamic";

import NoSSR from "@/src/components/sembly-ui/components/NoSSR/NoSSR";
import ReduxProviderWrapper from "@/src/core/ReduxProviderWrapper";
import DragAndDropProvider from "@/src/pages/SpaceLandingPage/HOCs/DragAndDropProvider";
import { deleteAllIndexedDBDatabases } from "@/src/utils/IndexDBUtil";

const CreateReactAppEntryPoint = dynamic(() => import("./App"), { ssr: false });

function App() {
  // This is for cleanup legacy index db that stored, will be remove after couple of months.
  useEffect(() => {
    deleteAllIndexedDBDatabases();
  }, []);
  return (
    <NoSSR>
      <DragAndDropProvider>
        <ReduxProviderWrapper>
          <CreateReactAppEntryPoint />
        </ReduxProviderWrapper>
      </DragAndDropProvider>
    </NoSSR>
  );
}

export default App;
